import React from "react";
import './App.css';
import {Button, Modal} from "antd";

function App() {
  return (
      <div className="App">
          <Modal width={480} title={
              <div style={{ width: '100%', cursor: 'move'}}>
                  官方通告
              </div>
          }
                 destroyOnClose={true}
                 open={true}
                 closable={false}
                 footer={()=>{
                     return <Button style={{backgroundColor: "#4F81EA"}} type={"primary"} onClick={()=>{
                         window.location.href = 'https://user.shanghailingye.cn/';
                     }}>前往新域名</Button>
                 }}
          >
              {
                  <div style={{padding: "0px 16px"}}>
                      <h1>旧域名已停止使用</h1>
                      <p>为了给您提供更好的服务和体验，我们的旧域名已经停止使用。</p>
                      <p>请访问我们的新域名：<a href={'https://user.shanghailingye.cn/'}>https://user.shanghailingye.cn/</a> </p>
                      <p>感谢您的理解和支持！</p>
                  </div>
              }
          </Modal>
      </div>
  );
}

export default App;
